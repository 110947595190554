import React from 'react';
import {
  BooleanField,
  Datagrid,
  DateField,
  EditButton,
  Pagination,
  ReferenceField,
  ReferenceManyField,
  Show,
  Tab,
  TabbedShowLayout,
  TextField,
  TopToolbar,
  useRecordContext,
} from 'react-admin';
import CurrencyField from '../Custom/CurrencyField';
import DepositButton from './DepositButton';
import PayOutButton from './PayOutButton';

const WalletShowActions = () => {
  const record = useRecordContext();
  return (
    <TopToolbar>
      <EditButton />
      {record && (
        <>
          <DepositButton walletId={record.id} amount={record.amount} />
          <PayOutButton walletId={record.id} amount={record.amount} />
        </>
      )}
    </TopToolbar>
  );
};

export default (props) => (
  <Show {...props} actions={<WalletShowActions />}>
    <TabbedShowLayout>
      <Tab label="Wallet">
        <TextField source="id" />
        <ReferenceField source="userId" reference="users" link="show">
          <TextField source="name" />
        </ReferenceField>
        <CurrencyField source="amount" />
        <BooleanField source="isBlocked" />
        <DateField source="createdAt" />
        <DateField source="updatedAt" />
      </Tab>
      <Tab label="Transactions">
        <ReferenceManyField
          reference="transactions"
          target="walletId"
          pagination={<Pagination />}
          addLabel={false}
          sort={{ field: 'createdAt', order: 'DESC' }}
        >
          <Datagrid rowClick="show">
            <DateField source="createdAt" />
            <TextField source="type" />
            <CurrencyField source="price" />
            <CurrencyField source="revenue" />
            <TextField source="note" />
            <ReferenceField source="saleByStaffId" reference="staffs">
              <TextField source="name" />
            </ReferenceField>
            <TextField source="status" />
            <DateField source="updatedAt" />
          </Datagrid>
        </ReferenceManyField>
      </Tab>
    </TabbedShowLayout>
  </Show>
);
