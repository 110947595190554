import {
  Datagrid,
  DateField,
  List,
  ReferenceField,
  ReferenceInput,
  SelectInput,
  TextField,
} from "react-admin";
import React from "react";
import CurrencyField from "../Custom/CurrencyField";

const TransactionFilters = [
  <SelectInput
    alwaysOn
    source="type" choices={[
    { id: "buy", name: "buy" },
    { id: "sell", name: "sell" },
    { id: "deposit", name: "deposit" },
  ]}
  />,
  <ReferenceInput source="saleByStaffId" reference="staffs" alwaysOn>
    <SelectInput optionText="name" allowEmpty resettable />
  </ReferenceInput>,
];

export default props => (
  <List {...props} sort={{ field: "createdAt", order: "DESC" }} filters={TransactionFilters}>
    <Datagrid rowClick={(id, basePath, record) => `transactions/${record.id}/show`}>
      <ReferenceField source="userId" reference="users"><TextField source="name" /></ReferenceField>
      <CurrencyField source="price" />
      <CurrencyField source="revenue" />
      <ReferenceField
        source="ledgerId"
        reference="ledgers"
      ><TextField source="name" /></ReferenceField>
      <TextField source="status" />
      <TextField source="type" />
      <ReferenceField
        source="tutorId"
        reference="users"
      ><TextField source="name" /></ReferenceField>
      <TextField source="note" />
      <ReferenceField
        source="saleByStaffId"
        reference="staffs"
      ><TextField source="name" /></ReferenceField>
      <DateField source="startPeriodTimestamp" showTime />
      <DateField source="createdAt" showTime />
    </Datagrid>
  </List>
);
