import React from 'react';
import {
  BooleanField,
  Datagrid,
  DateField,
  ImageField,
  List,
  ReferenceField,
  TextField,
} from 'react-admin';

export default (props) => (
  <List {...props} sort={{ field: 'createdAt', order: 'DESC' }}>
    <Datagrid rowClick="show">
      <ReferenceField source="userId" reference="users" link="show">
        <TextField source="name" />
      </ReferenceField>
      <ReferenceField source="userId" reference="users" link="show">
        <ImageField source="avatar" sx={{ width: 50, height: 50 }} />
      </ReferenceField>
      <TextField source="languages" />
      <TextField source="education" />
      <TextField source="targetStudent" />
      <BooleanField source="isActivated" />
      <DateField source="createdAt" />
      <DateField source="updatedAt" />
    </Datagrid>
  </List>
);
