import {
  AutocompleteInput,
  Create, SimpleForm, TextInput, ReferenceInput, DateInput, BooleanInput, TextField, SelectInput,
} from "react-admin";

export default props => (<Create {...props}>
  <SimpleForm>
    <ReferenceInput
      source="userId"
      reference="users"
    ><AutocompleteInput optionText="name" /></ReferenceInput>
    <DateInput source="callerNumber" />
    <TextInput source="name" />
    <TextInput source="zohoTag" />
    <TextInput source="startWorkingDay" />
    <TextInput source="endWorkingDay" />
    <TextInput source="zohoTagId" />
    <BooleanInput source="visible" />
  </SimpleForm>
</Create>);
