import React from "react";
import {
  AutocompleteInput,
  BooleanField,
  Datagrid,
  DateField,
  List,
  ReferenceField,
  ReferenceInput,
  TextField,
} from "react-admin";
import LinkField from "../Custom/LinkField";
import Config from "../../Config";

const BookingViewFilters = [
  <ReferenceInput label="Tutor" source="tutorId" reference="users" perPage={5000} alwaysOn>
    <AutocompleteInput optionText="name" />
  </ReferenceInput>,
  <ReferenceInput label="Student" source="userId" reference="users" perPage={5000} alwaysOn>
    <AutocompleteInput optionText="name" />
  </ReferenceInput>,
];

export default props => (
  <List {...props} sort={{ field: "startPeriodTimestamp", order: "DESC" }}
        filters={BookingViewFilters}
  >
    <Datagrid rowClick={(id, basePath, record) => `bookings/${record.id}/show`}>
      <ReferenceField source="userId" reference="users" label="Student"><TextField source="name" /></ReferenceField>
      <ReferenceField
        source="tutorId"
        reference="users"
        label="Tutor"
      ><TextField source="name" /></ReferenceField>
      <DateField source="startPeriodTimestamp" showTime />
      <DateField source="endPeriodTimestamp" showTime />
      <DateField source="createdAt" showTime />
      <DateField source="updatedAt" showTime />
      <BooleanField source="isDeleted" />
      <TextField source="studentRequest" />
      <TextField source="status" />
      <TextField source="tutorReview" />
      <LinkField source="studentMeetingLink" prefix={Config.appUrl} text="Student lesson link" />
      <LinkField source="tutorMeetingLink" prefix={Config.appUrl} text="Tutor lesson link" />
    </Datagrid>
  </List>
);
