import {
  BooleanField,
  Confirm,
  DateField,
  EditButton,
  ReferenceField,
  Show,
  SimpleShowLayout,
  TextField,
  TopToolbar,
} from 'react-admin';
import React from 'react';
import Button from '@mui/material/Button';
import Config from '../../Config/index';
import LinkField from '../Custom/LinkField';

const axios = require('axios');

function cancelBooking(bookingId) {
  const auth = JSON.parse(localStorage.getItem('auth'));
  axios
    .post(
      `${Config.apiUrl}/booking/cancel`,
      { bookingId },
      { headers: { Authorization: `Bearer ${auth.accessToken}` } },
    )
    .then((response) => {})
    .catch((error) => {
      console.log(error);
    });
}

const CancelBookingButton = ({ bookingId }) => {
  const [isOpen, setIsOpen] = React.useState(false);
  return (
    <>
      <Button
        color="primary"
        onClick={() => {
          setIsOpen(true);
        }}
      >
        Cancel booking
      </Button>
      <Confirm
        isOpen={isOpen}
        title="Do you want to cancel this booking?"
        onConfirm={() => {
          cancelBooking(bookingId);
          setIsOpen(false);
        }}
        onClose={() => {
          setIsOpen(false);
        }}
        confirm="Yes"
        cancel="No"
      />
    </>
  );
};
const BookingShowActions = ({ basePath, data, resource }) => (
  <TopToolbar>
    <EditButton basePath={basePath} record={data} />
    {data && <CancelBookingButton bookingId={data.id} />}
  </TopToolbar>
);

export default (props) => (
  <Show actions={<BookingShowActions />} {...props}>
    <SimpleShowLayout>
      <ReferenceField source="userId" reference="users">
        <TextField source="name" />
      </ReferenceField>
      <ReferenceField source="scheduleDetailId" reference="scheduleDetails">
        <TextField source="id" />
      </ReferenceField>
      <DateField source="createdAt" showTime />
      <DateField source="updatedAt" showTime />
      <BooleanField source="isDeleted" />
      <LinkField source="tutorMeetingLink" prefix={Config.appUrl} text="Tutor lesson link" />
      <LinkField source="studentMeetingLink" prefix={Config.appUrl} text="Student lesson link" />
      <TextField source="studentRequest" />
      <TextField source="status" />
      <TextField source="tutorReview" />
      <TextField source="scoreByTutor" />
      <TextField source="recordUrl" />
    </SimpleShowLayout>
  </Show>
);
