import {
  Edit,
  NumberInput,
  ReferenceField,
  ReferenceInput,
  SelectInput,
  SimpleForm,
  TextField,
  TextInput,
} from "react-admin";

export default props => (
  <Edit {...props}>
    <SimpleForm redirect="show">
      <TextField source="id" />
      <ReferenceField
        source="walletId"
        reference="wallets"
      ><TextField source="id" /></ReferenceField>
      <NumberInput source="price" />
      <NumberInput source="revenue" />
      <TextInput source="type" />
      <ReferenceInput source="ledgerId" reference="ledgers">
        <SelectInput optionText="name" allowEmpty resettable />
      </ReferenceInput>
      <TextInput source="note" />
      <ReferenceInput reference="staffs" source="saleByStaffId">
        <SelectInput optionText="name" allowEmpty resettable />
      </ReferenceInput>
    </SimpleForm>
  </Edit>
);
