import {
  Edit, SimpleForm, TextInput, ReferenceInput, DateInput, BooleanInput, TextField, SelectInput,
} from "react-admin";

export default props => (<Edit {...props}>
  <SimpleForm>
    <TextField source="id" />
    <ReferenceInput
      source="userId"
      reference="users"
    ><SelectInput optionText="name" /></ReferenceInput>
    <DateInput source="callerNumber" />
    <TextInput source="name" />
    <TextInput source="zohoTag" />
    <TextInput source="startWorkingDay" />
    <TextInput source="endWorkingDay" />
    <TextInput source="zohoTagId" />
    <BooleanInput source="visible" />
  </SimpleForm>
</Edit>);
