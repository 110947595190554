import React, { useState } from 'react';
import {
  Button,
  Form,
  ReferenceInput,
  required,
  SaveButton,
  SelectInput,
  TextInput,
  useCreate,
  useNotify,
  useUpdate,
} from 'react-admin';
import IconCancel from '@material-ui/icons/Cancel';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import DialogActions from '@mui/material/DialogActions';
import { v4 as uuidv4 } from 'uuid';

const DepositButton = ({ walletId, amount }) => {
  const [showDialog, setShowDialog] = useState(false);
  const [create, { isLoading }] = useCreate('transactions');
  const [update, { updateLoading }] = useUpdate('wallets');
  const notify = useNotify();
  const handleClick = () => {
    setShowDialog(true);
  };

  const handleCloseClick = () => {
    setShowDialog(false);
  };

  const handleSubmit = async (values) =>
    create(
      'transactions',
      {
        data: {
          id: uuidv4(),
          walletId,
          price: values.price,
          revenue: values.revenue,
          ledgerId: values.ledgerId,
          saleByStaffId: values.saleByStaffId,
          note: values.note,
          status: 'success',
          type: 'deposit',
        },
      },
      {
        onSuccess: () => {
          update(
            'wallets',
            {
              id: walletId,
              data: {
                amount: parseInt(amount, 10) + parseInt(values.price, 10),
              },
              previousData: {
                amount,
              },
            },
            {
              onSuccess: () => {
                notify('Deposit successful', 'info');
                setShowDialog(false);
              },
              onError: ({ error }) => {
                notify(error.message, 'error');
              },
            },
          );
        },
        onError: ({ error }) => {
          notify(error.message, 'error');
        },
      },
    );
  return (
    <>
      <Button onClick={handleClick} label="Deposit" />
      <Dialog fullWidth open={showDialog} onClose={handleCloseClick} aria-label="Deposit">
        <DialogTitle>Deposit</DialogTitle>

        <Form resource="posts" onSubmit={handleSubmit}>
          <DialogContent>
            <TextInput source="price" validate={required()} fullWidth />
            <TextInput source="revenue" defaultValue={0} fullWidth />
            <ReferenceInput source="ledgerId" reference="ledgers">
              <SelectInput optionText="name" allowEmpty resettable />
            </ReferenceInput>
            <ReferenceInput source="saleByStaffId" reference="staffs">
              <SelectInput optionText="name" allowEmpty resettable />
            </ReferenceInput>
            <TextInput source="note" fullWidth />
          </DialogContent>
          <DialogActions>
            <Button label="ra.action.cancel" onClick={handleCloseClick} disabled={isLoading}>
              <IconCancel />
            </Button>
            <SaveButton />
          </DialogActions>
        </Form>
      </Dialog>
    </>
  );
};
export default DepositButton;
